<template>
  <v-layout row wrap>
    <v-flex xs12 md12>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs2 md2>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="date"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      persistent-hint
                      :value="computedDataInicial"
                      label="Data Inicial"
                      readonly
                      v-on="on"
                      outline
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="filtros.data_inicial"
                    locale="pt-br"
                    no-title
                    scrollable
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs2 md2>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="date2"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      persistent-hint
                      :value="computedDataFinal"
                      label="Data Final"
                      readonly
                      v-on="on"
                      outline
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="filtros.data_final"
                    locale="pt-br"
                    no-title
                    scrollable
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs4 md4>
                <v-autocomplete
                  item-value="codigo"
                  item-text="nome_doc"
                  v-model="filtros.segurado_codigo"
                  :items="segurados"
                  label="Segurado"
                  placeholder=" "
                  outline
                  clearable
                  :return-object="false"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs3 md3>
                <v-autocomplete
                  item-value="codigo"
                  item-text="descricao"
                  v-model="filtros.situacao"
                  :items="situacoes"
                  label="Situação"
                  placeholder=" "
                  outline
                  clearable
                  :return-object="false"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs1 md1>
                <v-btn fab dark color="primary" @click="consultaApolice()">
                  <v-icon dark large>search</v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  color="primary"
                  v-if="this.items.length > 0"
                  @click="printConsulta()"
                >
                  <v-icon dark large>print</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1"
          item-key="id"
          :rows-per-page-items="[5,10,20,50,100]"
          dense
        >
          <template slot="no-data">
            <div class="text-xs-center">Sem registros.</div>
          </template>
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.num_operacao || '' }}</td>
              <td>{{ props.item.nome_segurado }}</td>
              <td>{{ props.item.documento }}</td>
              <td>{{ props.item.plano_descricao}}</td>
              <td>{{ props.item.preco | currency}}</td>
              <td>{{ props.item.data_cadastro }}</td>
              <td v-if="props.item.situacao === 'GE'">Gerado</td>
              <td v-if="props.item.situacao === 'PE'">Pendente</td>
              <td v-if="props.item.situacao === 'VI'">Vigênte</td>
              <td v-if="props.item.situacao === 'VE'">Vencido</td>
              <td v-if="props.item.situacao === 'CA'">Cancelado</td>
            </tr>
          </template>
          <template v-slot:no-results>
            <div class="text-xs-center">Sem resultados para a pesquisa "{{ search }}".</div>
          </template>

          <template v-slot:footer>
            <td :colspan="headers.length">
              <div class="text-xs-right">
                <b>Total R$ {{computedTotalVenda | currency}}</b>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import * as moment from 'moment'
const seguradoModule = () => import('./../../services/segurado')
const consultaModule = () => import('./../../services/consulta')
const reportModule = () => import('./../../services/reports')

export default {
  name: 'ConsultaApolices',
  data () {
    return {
      valid: true,
      search: '',
      date: new Date().toISOString().substr(0, 7),
      date2: new Date().toISOString().substr(0, 7),
      menu: false,
      menu2: false,
      segurados: [],
      situacoes: [
        {
          codigo: '0',
          descricao: 'Todos'
        },
        {
          codigo: 'GE',
          descricao: 'Gerado'
        },
        {
          codigo: 'PE',
          descricao: 'Pendente'
        },
        {
          codigo: 'VI',
          descricao: 'Vigênte'
        },
        {
          codigo: 'VE',
          descricao: 'Vencido'
        },
        {
          codigo: 'CA',
          descricao: 'Cancelado'
        }
      ],
      filtros: {
        data_inicial: new Date().toISOString().substr(0, 10),
        data_final: new Date().toISOString().substr(0, 10),
        segurado_codigo: 0,
        situacao: '0'
      },
      headers: [
        { text: 'Nº da operação', align: 'left', value: 'num_operacao' },
        { text: 'Segurado', align: 'left', value: 'nome_segurado' },
        { text: 'Documento', align: 'left', value: 'documento' },
        { text: 'Produto', align: 'left', value: 'plano_descricao' },
        { text: 'Valor(R$)', align: 'left', value: 'preco' },
        { text: 'Data de cadastro', align: 'left', value: 'data_cadastro' },
        { text: 'Situação', align: 'left', value: '' }
      ],
      items: [],
    }
  },
  computed: {
    computedDataInicial () {
      return this.filtros.data_inicial ? moment(this.filtros.data_inicial).format('DD/MM/YYYY') : ''
    },
    computedDataFinal () {
      return this.filtros.data_final ? moment(this.filtros.data_final).format('DD/MM/YYYY') : ''
    },
    computedTotalVenda () {
      if (this.items.length > 0) {
        return this.items
          .filter(e => parseFloat(e.preco) > 0)
          .map(element => parseFloat(element.preco))
          .reduce((total, valor) => {
            return total += parseFloat(valor)
          })
      } else {
        return 0
      }
    }

  },
  mounted () {
    this.getSeguradoFiltro()
  },
  methods: {
    async consultaApolice () {
      let consultaService = await consultaModule()
      let resp = await consultaService.getApolices(this.$axios, { ...this.filtros })
      if (resp.status === 200 && resp.data) {
        this.items = resp.data
      }
    },
    async printConsulta () {
      let reportService = await reportModule()
      let resp = await reportService.getApolicesPDF(this.$axios, { ...this.filtros })
      if (resp.status === 200 && resp.data) {
        let blob = resp.data
        let link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${moment().format('DD-MM-YYYY_HH-mm-ss')}-consulta-apolices.pdf`
        document.body.append(link)
        link.click()
        link.remove()
        window.addEventListener('focus', () => URL.revokeObjectURL(link.href), { once: true })
      }
    },
    async getSeguradoFiltro () {
      let seguradoService = await seguradoModule()
      let resp = await seguradoService.getFiltro(this.$axios)
      if (resp.status === 200 && resp.data) {
        this.segurados = resp.data
        this.segurados.unshift({
          codigo: 0,
          nome_doc: 'Todos'
        })
      }
    }
  }
}
</script>